import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import ReactMarkdown from "react-markdown"

const query = graphql`
{
  strapiContactpage {
    contactdata {
      icon {
        localFile {
          url
        }
      }
      content
      subject
    }
  }
}
`


const ContactInfo = () => {
    const data = useStaticQuery(query)

    const {strapiContactpage: {contactdata}} = data

    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">

                    {contactdata.map((cd, idx) => {
                        return (
                            <div key={idx} className="col-lg-4 col-md-6">
                                <div className="contact-info-box">
                                    <div className="icon">
                                        <img src={cd.icon.localFile.url} alt={cd.icon.alternativeText} />
                                    </div>
                                    <h3>{cd.subject}</h3>
                                    <ReactMarkdown children={cd.content} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ContactInfo
