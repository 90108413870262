import React from 'react'
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import Seo from "../components/App/Seo";
import {graphql} from "gatsby";
import Navbar from "../components/App/Navbar";

const Contact = ({data}) => {

    const {
        strapiContactpage: {seo},
        strapiSeolocalization: {contact_locale}
    } = data


    return (
        <Layout>
            <Seo seotags={seo} loc={contact_locale} />
            <Navbar />
            <PageBanner
                pageTitle="Contact" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Contact" 
            />
            <ContactInfo />
            <ContactForm />
            <Footer />
        </Layout>
    );
}

export const query = graphql`
{
strapiContactpage {
    seo {
      metatitle
      metadescription
      chanoncial_link
    }
  }
  strapiSeolocalization {
    contact_locale {
    default_localization {
        default_url
      }
      localization {
        url
        iso_code {
          code
        }
      }
    }
  }
}
`


export default Contact
